import { version } from '../package.json';

export const config = {
  version,
  api: getApi(),
  lsVersion: 1,
};

function getApi() {
  return 'https://gsystem-api.fom.ru';
}
