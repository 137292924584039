import React from 'react';

import { handleError } from '../../services/error.service';
import { formatDate } from '../../services/fmt.service';
import { getLsItem, LSK_SEARCH_SORT, setLsItem } from '../../services/ls.service';
import { showModal } from '../../services/modal.service';
import * as sdk from '../../services/sdk.service';
import { Formulation, FormulationSort, FormulationUnit } from '../../services/sdk.service';
import { scrollToTop } from '../../services/ui.service';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MUnits } from '../modals/MUnits';
import { Option, Select } from '../Select';

interface S {
  items: Formulation[];
  page: number;
  search: string;
  sort: FormulationSort;
  applied: { search: string; sort: FormulationSort };
  isLoading: boolean;
}

export class PSearch extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      items: [],
      page: 1,
      search: '',
      sort: getLsItem<FormulationSort>(LSK_SEARCH_SORT) || FormulationSort.byUsageCount,
      applied: { search: '', sort: FormulationSort.byUsageCount },
      isLoading: false,
    };
  }

  render() {
    const { items, search, sort, applied, isLoading } = this.state;
    return (
      <div className="PSearch pb-3">
        <div className="d-flex mb-3">
          <input
            className="form-control"
            type="text"
            placeholder="Введите запрос"
            value={search}
            disabled={isLoading}
            autoFocus
            onChange={(e) => this.onSearchChange(e)}
            onKeyDown={(e) => this.onKeyDown(e)}
          />
          <Select
            options={this.getSortOptions()}
            className="ml-3"
            value={sort}
            disabled={isLoading}
            onChange={(value) => this.onSortChange(value as FormulationSort)}
          />
          <Button
            className="ml-3"
            type="primary"
            text="Искать"
            disabled={!search || items.length > 0 || isLoading}
            onClick={() => this.onSearch()}
          />
        </div>
        {items.length > 0 && this.renderList()}
        {applied.search && items.length === 0 && this.renderEmptyResult()}
      </div>
    );
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({
      items: [],
      search: e.target.value,
      applied: { search: '', sort: FormulationSort.byUsageCount },
    });
  }

  onKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      this.search();
    }
  }

  async onSortChange(value: FormulationSort) {
    const { items } = this.state;
    setLsItem(LSK_SEARCH_SORT, value);
    if (items.length === 0) {
      this.setState({
        items: [],
        sort: value,
        applied: { search: '', sort: FormulationSort.byUsageCount },
      });
    } else {
      this.setState({ sort: value, isLoading: true }, () => this.search());
    }
  }

  onSearch() {
    this.search();
  }

  async onItemSelect(item: Formulation) {
    const formulationText = item.text;
    let units: FormulationUnit[] = [];
    try {
      units = await sdk.getUnits({ formulationText });
    } catch (e) {
      handleError(e);
      return;
    }
    await showModal(MUnits, { formulationText, units });
  }

  onPageChange(page: number) {
    this.setState({ page });
    scrollToTop();
  }

  // render helpers

  renderList() {
    const { items, page } = this.state;
    return (
      <List
        items={items}
        columns={this.getColumns()}
        pageSize={20}
        pageNumber={page}
        onItemSelect={(x) => this.onItemSelect(x)}
        onPageChange={(x) => this.onPageChange(x)}
      />
    );
  }

  renderEmptyResult() {
    return <div>Ничего не найдено</div>;
  }

  // other helpers

  getSortOptions(): Option[] {
    return [
      { value: FormulationSort.byUsageCount, title: 'Сортировать по числу использований' },
      { value: FormulationSort.byLastUnitDate, title: 'Сортировать по последнему опросу' },
    ];
  }

  getColumns(): Column<Formulation>[] {
    return [
      {
        name: 'Формулировка',
        value: (item) => item.text,
      },
      {
        name: 'Использований',
        value: (item) => item.usageCount,
        headClassName: 'w-160px text-center',
        cellClassName: () => 'text-center',
      },
      {
        name: 'Последний опрос',
        value: (item) => formatDate(item.lastUnitDate),
        headClassName: 'w-160px text-center',
        cellClassName: () => 'text-center',
      },
    ];
  }

  async search() {
    const { search, sort } = this.state;
    if (!search) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const items = await sdk.search({ search, sort });
      this.setState({
        items,
        page: 1,
        applied: { search, sort },
        isLoading: false,
      });
    } catch (e) {
      this.setState({ isLoading: false });
      handleError(e);
    }
  }
}
