import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../lib/utils.lib';
import { getUser } from '../services/auth.service';
import { goTo, isCurrentPath } from '../services/router.service';
import { VoidLink } from './VoidLink';

export class Navbar extends React.Component {
  render() {
    const user = getUser();
    return (
      <nav className="Navbar navbar navbar-expand navbar-light bg-light">
        <div className="container">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">{this.renderNavItem('/search', 'Поиск формулировок')}</ul>
            <div className="Navbar_userDropdown dropdown">
              <button
                className="btn btn-outline-secondary btn-sm dropdown-toggle"
                id="navbarDropdown"
                data-toggle="dropdown"
              >
                {user ? user.username : 'User'}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <VoidLink className="dropdown-item" onClick={() => this.onLogout()}>
                  Выход
                </VoidLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  // event handlers

  async onLogout() {
    goTo('/login');
  }

  // render helpers

  renderNavItem(url: string, title: string) {
    const isActive = isCurrentPath(url);
    return (
      <li className={cn('nav-item', isActive && 'active')}>
        <Link to={url} className="nav-link">
          {title}
        </Link>
      </li>
    );
  }
}
