import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { cn } from '../lib/utils.lib';
import { getUser } from '../services/auth.service';
import { getModals, Modal } from '../services/modal.service';
import { getHistory, goTo } from '../services/router.service';
import { registerUiUpdater } from '../services/ui.service';
import { Navbar } from './Navbar';
import { PLogin } from './pages/PLogin';
import { PSearch } from './pages/PSearch';

export class App extends React.Component {
  render() {
    return (
      <Router history={getHistory()}>
        <Switch>
          <Route path="/login">
            <PLogin />
          </Route>
          <Auth />
        </Switch>
      </Router>
    );
  }

  componentDidMount() {
    registerUiUpdater(() => {
      this.forceUpdate();
    });
  }
}

function Auth() {
  if (!getUser()) {
    goTo('/login');
    return null;
  }
  return (
    <div>
      <Navbar />
      <div className="container mt-3">
        <Switch>
          <Route path="/search" exact>
            <PSearch />
          </Route>
          <Route path="*">
            <RefirectToHome />
          </Route>
        </Switch>
      </div>
      {renderModals()}
    </div>
  );
}

function RefirectToHome() {
  const url = '/search';
  return <Redirect to={url} />;
}

function renderModals() {
  const modals = getModals();
  const n = modals.length;
  if (n === 0) {
    return null;
  }
  return (
    <div>
      {modals.map((m, i) => renderModal(m, i === n - 1))}
      <div key="backdrop" className="modal-backdrop show"></div>
    </div>
  );
}

function renderModal(modal: Modal, isVisible: boolean) {
  const { component, props, key } = modal;
  const className = cn('modal', isVisible ? 'd-block' : '');
  return (
    <div key={key} className={className}>
      {React.createElement(component, props)}
    </div>
  );
}
