import * as dateFns from 'date-fns';

export function formatDate(date: string | number | null | undefined) {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'dd.MM.yyyy');
}

export function formatQuestionType(value: number) {
  switch (value) {
    case 4:
      return 'Закрытый вопрос';
    case 5:
      return 'Текстовый вопрос';
    case 6:
      return 'Числовой вопрос';
    default:
      return '?';
  }
}
